// extracted by mini-css-extract-plugin
export var buttonSendContact = "contacto-module--buttonSendContact--7CVTi";
export var checkBox = "contacto-module--checkBox--Zh8Mn";
export var checkContainer = "contacto-module--checkContainer----j1+";
export var container = "contacto-module--container--wrHoC";
export var containerColumn = "contacto-module--containerColumn--VaMsG";
export var itemsContainer = "contacto-module--itemsContainer---yKjw";
export var selectBox = "contacto-module--selectBox--161LB";
export var textBox = "contacto-module--textBox--k86a2";
export var textPhoneValue = "contacto-module--textPhoneValue--ljAKH";
export var textarea = "contacto-module--textarea--fm+ra";